<template>
    <div>
      <b-modal
          id="modal-form"
          ok-only
          centered
          size="md"
          content-class="shadow"
          hide-footer
        >
        <template #modal-title> <div style="font-size:24px; font-weight:600;">{{createMode ? 'Tambah' : 'Ubah'}} {{ isProvinsi ? 'Propinsi' : 'Kabupaten / Kota'}}</div></template>
          <div >
            <b-form-row>
              <b-col>
                <b-row class="d-flex align-items-center">
                  <b-col sm="5" >
                    <p style="font-weight: 600; margin:0px;">Kode {{ isProvinsi ? 'Propinsi' : 'Kabupaten / Kota'}}</p>
                  </b-col>:<b-col sm="6" class="d-flex">
                    <b-form-input style="width:45px; margin-right:5px" v-if="!isProvinsi" v-model="selectedProv.kode" ></b-form-input>
                      <b-form-input v-model="dataSubmit.kode" maxlength="3" placeholder="Masukan Kode"></b-form-input>
                  </b-col>
                </b-row>  
              </b-col>
            </b-form-row>
            <b-form-row class="pt-4">
              <b-col>
                <b-row class="d-flex align-items-center">
                  <b-col sm="5" >
                    <p style="font-weight: 600; margin:0px;">Nama {{ isProvinsi ? 'Propinsi' : 'Kabupaten / Kota'}}</p>
                  </b-col>:<b-col sm="6">
                      <b-form-input v-model="dataSubmit.nama" placeholder="Masukan Nama"></b-form-input>
                  </b-col>
                </b-row>  
              </b-col>
            </b-form-row>
            <b-form-row class="pt-4">
              <b-col>
                <b-row class="d-flex align-items-center">
                  <b-col sm="5" >
                    <p style="font-weight: 600; margin:0px;">Longitude</p>
                  </b-col>:<b-col sm="6">
                      <b-form-input v-model="dataSubmit.latlong.long" placeholder="Masukan Longitude"></b-form-input>
                  </b-col>
                </b-row>  
              </b-col>
            </b-form-row>
            <b-form-row class="pt-4">
              <b-col>
                <b-row class="d-flex align-items-center">
                  <b-col sm="5" >
                    <p style="font-weight: 600; margin:0px;">Latitude</p>
                  </b-col>:<b-col sm="6">
                      <b-form-input v-model="dataSubmit.latlong.lat" placeholder="Masukan Latitude"></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
            </b-form-row>
            <b-form-row class="pt-4" v-if="isProvinsi">
              <b-col>
                <b-row class="d-flex align-items-center">
                  <b-col sm="5" >
                    <p style="font-weight: 600; margin:0px;">Zoom</p>
                  </b-col>:<b-col sm="6">
                      <b-form-input v-model="dataSubmit.zoom" type="number" min="0" max="12" placeholder="Masukan batasan zoom"></b-form-input>
                  </b-col>
                </b-row>  
              </b-col>
            </b-form-row>
            <div class="text-center">
              <b-button
                variant="outline-primary"
                class="mt-16 text-center mr-5"
                @click="$bvModal.hide('modal-form')"
              >
                Batal
              </b-button>
              <b-button
                variant="primary"
                class="mt-16 text-center"
                @click="submit()"
              >
              {{createMode ? 'Tambah' : 'Ubah'}}
              </b-button>
            </div>
          </div>
      </b-modal>
      <b-modal
        id="formKonfirmasi"
        ok-only
        centered
        size="md"
        content-class="shadow"
        hide-header
        hide-footer
      >
        <div>
          <b-container>
            <p style="font-size:18px; margin-top:20px;">
              Apakah anda yakin ingin Menghapus Kegiatan?
            </p>
          </b-container>
          <div class="text-center">
            <b-button
              variant="outline-secondary"
              class="mt-16 text-center mr-5"
              @click="$bvModal.hide('formKonfirmasi')"
            >
              Tidak
            </b-button>
            <b-button
              variant="danger"
              class="mt-16 text-center"
              @click="deleteData()"
            >
              Yakin
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-row>
            <b-col>
              <!-- <div class="form-row"> -->
                <div class="d-flex justify-content-between align-items-center shadow p-1 pr-3" style="background:white; border-radius:10px;">
                  <input
                    type="text"
                    class="form-control border-0"
                    v-model="search"
                    v-on:keyup.enter="submitSearch"
                    :placeholder="'Cari...'"
                  />
                  <b-button
                    size="sm"
                    style="width:80px;"
                    variant="primary"
                    @click="submitSearch()"
                  >
                    <v-icon
                      style="color:white;"
                      small
                    >mdi-magnify</v-icon>
                    Cari
                  </b-button>
                </div>
                
              <!-- </div> -->
            </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col class="d-flex align-items-center justify-content-between">
          <span style="font-size:16px; font-weight:bold;">Master Wilayah {{ !isProvinsi ? '/' + selectedProv.nama : ''}}</span>
          <div>
            <b-button v-if="!isProvinsi" variant="outline-secondary" class="mr-3" size="sm" @click="backToProv()">
            <v-icon class="pr-3">mdi-arrow-left</v-icon>
            Kembali
          </b-button>
                <b-button
                size="sm"
                variant="primary"
                @click="createWilayah()"
              >
                <v-icon
                  style="color:white;"
                  small
                >mdi-plus-box</v-icon>
                Tambah
              </b-button>
            </div>
        </b-col>
      </b-row>
       <div
            class="table-responsive mt-4"
            style="border-radius:15px;"
          >
            <table
              class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-bordered
          "
              style="font-size:14px !important;"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="min-width: 50px" class="text-capitalize"> {{ !isProvinsi ? 'Kabupaten / Kota' : 'Propinsi' }}</th>
                  <th v-if="!isProvinsi" style="min-width: 50px" class="text-capitalize"> Batas Wilayah</th>
                  <th style="max-width: 100px; width:100px;" class="text-capitalize">Aksi</th>
                  <!-- <th style="min-width: 50px" class="text-capitalize">Buka Pembekuan</th> -->
                </tr>
              </thead>
              <!-- <tbody v-if="list.length == 0">
                  <template>
                      <tr>
                          <td colspan="6"> -- Tidak ada permohonan -- </td>
                      </tr>
                  </template>
              </tbody> -->
              <tbody style="background: #FFFFFF;">
                <template v-for="(item, i) in listData">
                  <tr v-bind:key="i">
                    <td >
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ (i+1) + ((currentPage-1)*perPage)}}</span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{item.nama}}
                      </span>
                    </td>
                    <td v-if="!isProvinsi">
                      <b-form-checkbox size="lg" v-model="item.is_batas_negara" @change="switchBN(item)" name="check-button" switch>
                      </b-form-checkbox>
                    </td>
                    <td class="text-left">
                      <section class="d-flex justify-content-center align-items-center">
                        <b-button
                          variant="primary"
                          size="sm"
                          class="d-flex justify-content-center align-items-center"
                          style="width:30px;height:30px;margin:5px;"
                          @click="editWilayah(item)"
                        >
                          <v-icon
                            style="color:white;padding:0px;"
                            small
                          >mdi-pencil-outline</v-icon>
                        </b-button>
                        <b-button
                          variant="danger"
                          size="sm"
                          class="d-flex justify-content-center align-items-center"
                          style="width:30px;height:30px;margin:5px;"
                          @click="confirmDelete(item.id)"
                        >
                          <v-icon
                            style="color:white;padding:0px;"
                            small
                          >mdi-delete-outline</v-icon>
                        </b-button>
                        <b-button
                        @click="toKab(item)"
                        v-if="isProvinsi"
                          class="d-flex justify-content-center align-items-center"
                          variant="success"
                          size="sm"
                          style="width:30px;height:30px;margin:5px;"
                        >
                          >>
                        </b-button>
                        </section>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </b-col>
          </b-row>
    </div>
</template>
<style>
#search-card .card-body {
  padding:0px !important;
}
</style>
<script>
import Swal from "sweetalert2";
  // import { ModelSelect } from 'vue-search-select'

export default {
  name: "Sengketa",
  // components: {
  //     ModelSelect
  // },
  data() {
    return {
      createMode: true,
      currentPage: 1,
      currentOffset: 0,
      totalRows: 0,
      perPage: 20,
      query: {},
      listSengketa: [],
      search: '',
      dataSubmit: { nama: '', kode: '', tipe: 1, latlong: {lat: '', long: ''}, zoom: 0},
      id: null,
      listData: [],
      lisList: false,
      listDataData: [],
      selectedProv: null,
      isProvinsi: true
    }
  },
  mounted(){
    this.fetchData()
  },
  methods: {
    submitSearch(){
      this.fetchData()
    },
    createWilayah(){
      this.createMode = true
      this.dataSubmit = { nama: '', kode: '', tipe: 1, latlong: {lat: '', long: ''}, zoom: 0}
      this.$bvModal.show('modal-form')
    },
    backToProv(){
      this.search = ''
      this.isProvinsi = true
      this.fetchData()
    },
    fetchData(){
      if (this.search.length > 0 && this.search.length < 3) return;

      if(this.isProvinsi){
        this.lisList = true
        this.listDataData = []
        this.query = {
          offset: this.currentOffset,
          search : this.search,
          tipe: 1
        };
        this.itemCountGet(this.query)
        this.$store
        .dispatch("wilayahProvList", this.query)
        // .dispatch("getBankAccountOrganization", 4)
        .then((res) => {
          this.listData = res.data
          this.lisList =false
        })
      }else {
        this.lisList = true
        this.listDataData = []
        this.query = {
          offset: this.currentOffset,
          search : this.search,
          // kode: this.selectedProv.kode + "." + this.dataSubmit.kode,
          kode: this.selectedProv.kode + ".",
          tipe: 2
        };
        this.itemCountGet(this.query)
        this.$store
        .dispatch("wilayahKabList", this.query)
        // .dispatch("getBankAccountOrganization", 4)
        .then((res) => {
          this.listData = res.data
          this.lisList =false
        })
      }
    },
    paginateWilayah() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      this.listData = this.listDataData.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    async itemCountGet() {
      // if(q && q.bk || q.ss || q.ck || q.jo || q.sk || q.no){
      this.$store
        .dispatch("wilayahProvKabListCount", this.query)
        .then((res) => {
          this.totalRows = res.data.count
      })
    },
    editWilayah(item){
      this.createMode = false
      this.$bvModal.show('modal-form')
      this.id = item.id
      if(this.isProvinsi) this.dataSubmit.kode = item.kode
      else this.dataSubmit.kode = item.kode.split('.')[1]
      this.dataSubmit.nama = item.nama
      this.dataSubmit.latlong = item.latlong
      this.dataSubmit.zoom = item.zoom
      this.dataSubmit.tipe = item.tipe
    },
    confirmDelete(id){
      this.id = id
      this.$bvModal.show('formKonfirmasi')
    },
    toKab(item){
      this.isProvinsi = false
      this.selectedProv = item
      this.search = ''
      this.currentPage= 1
      this.currentOffset= 0
      this.fetchData()
    },
    switchBN(d){
      var body = d
      body.latlong = JSON.stringify(d.latlong)
      body.zoom = body.zoom == null ? 0 : body.zoom
      var urlpath = 'submitPatchWilayah'
        this.$store
        .dispatch(urlpath, [body, body.id])
        .then(() => {
          var context = this
          context.fetchData()
          Swal.fire(
                {
                  title: 'Status Batas Negara berhasil diubah',
                  icon: "success",
                  text: "",
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                  Swal.close();
                }
              );
        })
    },
    // switchEdit(d){
    //   var body = d
    //   body.latlong = JSON.stringify(d.latlong)
    //   body.zoom = body.zoom == null ? 0 : body.zoom
    //   var urlpath = 'submitPatchWilayah'
    //     this.$store
    //     .dispatch(urlpath, [body, body.id])
    //     .then(() => {
    //       var context = this
    //       context.fetchData()
    //       Swal.fire(
    //             {
    //               title: 'Status Batas Negara berhasil diubah',
    //               icon: "success",
    //               text: "",
    //               confirmButtonColor: "#063A69",
    //               confirmButtonText: "Tutup",
    //               cancelButtonText: false
    //             },
    //             function() {
    //               Swal.close();
    //             }
    //           );
    //     })
    // },
    deleteData() {
      var urlpath = 'deleteWilayah'
        this.$store
        .dispatch(urlpath, this.id)
        .then(() => {
          var context = this
          context.$bvModal.hide('formKonfirmasi')
                  context.fetchData()
          Swal.fire(
                {
                  title: "Data Berhasil Dihapus",
                  icon: "success",
                  text: "",
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                  context.$bvModal.hide('formKonfirmasi')
                  context.fetchData()
                  Swal.close();
                }
              );
        })
    },
    async submit(){
      
      var body = this.dataSubmit
      body.latlong = JSON.stringify(this.dataSubmit.latlong)
      body.zoom = parseInt(body.zoom)
      if(this.isProvinsi) {
        body.tipe = 1
      }else{ 
        body.tipe = 2
        body.kode = this.selectedProv.kode + "." + this.dataSubmit.kode
      }
      var urlpath = ''
      if(this.createMode) {
        urlpath = 'submitWilayah'
        this.$store
        .dispatch(urlpath, body)
        .then(() => {
          var context = this
          context.$bvModal.hide('modal-form')
          context.fetchData()
          Swal.fire(
                {
                  title: this.createMode ? "Data Berhasil Disimpan" : 'Data Berhasil Diubah',
                  icon: "success",
                  text: "",
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                  context.$bvModal.hide('modal-form')
                  context.fetchData()
                  Swal.close();
                }
              );
        })
      } else{ 
        urlpath = 'submitPatchWilayah'
        this.$store
        .dispatch(urlpath, [body, this.id])
        .then(() => {
          var context = this
          context.$bvModal.hide('modal-form')
          context.fetchData()
          Swal.fire(
                {
                  title: this.createMode ? "Data Berhasil Disimpan" : 'Data Berhasil Diubah',
                  icon: "success",
                  text: "",
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                  context.$bvModal.hide('modal-form')
                  context.fetchData()
                  Swal.close();
                }
              );
        })
      }
      
    }
  },
  watch:{
    currentPage(to){
      this.currentOffset = to - 1
      this.fetchData()
    },
    search: {
      handler() {
        // Check if the user has stopped typing
        if (this.typingTimeout) {
          clearTimeout(this.typingTimeout);
        }
        this.typingTimeout = setTimeout(() => {
          // User has stopped typing, perform your action here
          this.submitSearch()

        }, 500); // Adjust the delay as needed (1 second in this example)
      },
      immediate: false, // Set to true if you want to trigger it immediately
    }
  }
}
</script>